.app-multi-select  {
  @apply tw-rounded-lg tw-h-10 tw-transition-all tw-border-dark-300 tw-px-3 tw-flex tw-items-center;

  .p-checkbox {
    @apply app-checkbox;
  }

  .p-multiselect-trigger {
    @apply tw-text-dark-900 tw-bg-transparent #{!important};
  }

  &:not(.p-disabled).p-focus {
    @apply tw-border-dark-teal-600 tw-text-dark-teal-600;
  }

  &:not(.p-disabled):hover {
    @apply tw-bg-dark-100 tw-border-dark-300;
  }

  .p-multiselect-panel {
    @apply tw-rounded-xl tw-bg-white tw-p-0 tw-mt-2;
  }

  .p-multiselect-items-wrapper {
    @apply tw-p-4 tw-pt-0;
  }

  .p-multiselect-footer {
    @apply tw-p-4;
  }


  .p-multiselect-label {
    @apply tw-bg-transparent tw-text-body2 tw-text-dark-900 #{!important};
  }

  .p-multiselect-item {
    @apply tw-mt-5 tw-transition-all tw-p-0 tw-text-body2 #{!important};

    &:hover {
      @apply tw-bg-none #{!important};
    }
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    @apply tw-mr-2;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    @apply tw-bg-transparent tw-text-dark-900;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    @apply tw-bg-transparent;
  }
}




.app-multi-select  .p-multiselect-label {
  @apply tw-p-px tw-w-full #{!important};
}




