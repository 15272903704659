.app-dropdown  {
  @apply tw-rounded-lg tw-h-10 tw-transition-all tw-px-3 tw-flex tw-items-center ;
  @apply tw-border-dark-300  #{!important} ;

  .p-checkbox {
    @apply app-checkbox;
  }

  .p-dropdown-trigger {
    @apply tw-text-dark-900 tw-bg-transparent #{!important};
  }

  &:not(.p-disabled).p-focus {
    @apply tw-border-dark-teal-600 tw-text-dark-teal-600;
  }

  &:not(.p-disabled):hover {
    @apply tw-bg-dark-100 tw-border-dark-300;
  }

  .p-dropdown-panel {
    @apply tw-rounded-xl tw-bg-white tw-p-0 tw-mt-2;
  }

  .p-dropdown-items-wrapper {
    @apply tw-p-0;
  }

  .p-dropdown-footer {
    @apply tw-p-4;
  }


  .p-dropdown-label {
    @apply tw-bg-transparent tw-text-body2 tw-text-dark-900 #{!important};
  }

  .p-dropdown-item {
    @apply tw-px-4 tw-transition-all tw-py-2.5 tw-text-body2 #{!important};

    &:hover {
      @apply tw-bg-dark-100 #{!important};
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-checkbox {
    @apply tw-mr-2;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    @apply tw-bg-transparent tw-text-dark-900;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    @apply tw-bg-transparent;
  }
}




.app-multi-select  .p-dropdown-label {
  @apply tw-p-px;
}
