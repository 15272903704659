.app-datatable {
  @apply tw-table-fixed tw-text-body2 tw-font-semibold;
}

.app-datatable .p-datatable-header {
  @apply tw-p-0 tw-bg-dark-100;
}

.app-datatable .p-datatable-thead > tr > th {
  @apply tw-py-8 tw-px-5 tw-align-middle tw-text-body2 tw-font-semibold tw-border-b tw-border-dark-grey-100;
}

.app-datatable .p-datatable-tfoot > tr > td {
  @apply tw-py-8 tw-px-5 tw-border-b tw-border-dark-grey-100 tw-bg-dark-100;
}

.app-datatable .p-datatable-tbody > tr > td {
  @apply tw-py-8 tw-px-5 tw-border tw-border-dark-grey-200;
}
