// base
.app-btn {
  @apply tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-font-semibold tw-border-solid tw-whitespace-nowrap ;
}

// size
.app-btn-sm {
  @apply tw-text-body3 tw-h-8;
}

.app-btn-md {
  @apply tw-text-body2 tw-h-10;

  .p-button-label {
    @apply tw-text-body2;
  }
}

.app-btn-lg {
  @apply tw-text-body1 tw-h-12;
}

// primary
.app-btn-primary {
  @apply app-btn tw-bg-dark-teal-600 tw-text-crystal-white-100 tw-border-dark-teal-600;

  &:enabled:hover {
    @apply tw-bg-dark-teal-700;
  }

  &:disabled {
    @apply tw-bg-dark-grey-500 tw-text-dark-grey-300 tw-border-dark-grey-500;
  }
}

.app-btn-primary-sm {
  @apply app-btn-primary app-btn-sm;
}

.app-btn-primary-md {
  @apply app-btn-primary app-btn-md;
}

.app-btn-primary-lg {
  @apply app-btn-primary app-btn-lg;
}

// secondary
.app-btn-secondary {
  @apply app-btn tw-border tw-border-dark-teal-600 tw-bg-transparent tw-text-dark-teal-600;

  &:enabled:hover {
    @apply tw-bg-deep-green-100 tw-text-dark-teal-600;
  }

  &:disabled {
    @apply tw-border-dark-grey-500 tw-text-dark-grey-500;
  }
}

.app-btn-secondary-sm {
  @apply app-btn-secondary app-btn-sm;
}

.app-btn-secondary-md {
  @apply app-btn-secondary app-btn-md;
}

.app-btn-secondary-lg {
  @apply app-btn-secondary app-btn-lg;
}

// secondary grey
.app-btn-secondary-grey {
  @apply app-btn tw-border tw-border-dark-300 tw-bg-transparent tw-text-dark-teal-600;

  &:enabled:hover {
    @apply tw-bg-dark-100 tw-text-dark-teal-600 tw-border-dark-300;
  }

  &:disabled {
    @apply tw-border-dark-grey-500 tw-text-dark-300;
  }
}

.app-btn-secondary-grey-sm {
  @apply app-btn-secondary-grey app-btn-sm;
}

.app-btn-secondary-grey-md {
  @apply app-btn-secondary-grey app-btn-md;
}

.app-btn-secondary-grey-lg {
  @apply app-btn-secondary-grey app-btn-lg;
}

//warn

// Warning Button Styles (used as Reject button)
.app-btn-warn {
  @apply app-btn tw-border tw-bg-transparent;

  // Default state
  color: var(--app-red-700); // Main color for text
  border-color: var(--app-red-700); // Main color for border

  // Hover (enabled) state with a light red tint on background
  &:enabled:hover {
    @apply tw-bg-red-50 tw-text-red-700;
  }

  // Disabled state with white background and softer red border
  &:disabled {
    @apply tw-border-red-400 tw-text-red-500;
    opacity: 0.8 !important; // Slightly reduced opacity to indicate disabled
  }
}
