// base
.app-toggle-btn {
  @apply tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-bg-transparent tw-text-dark-900 tw-border tw-border-dark-300;

  &:enabled:hover {
    @apply tw-bg-dark-100;
  }

  &:disabled {
    @apply tw-bg-dark-grey-500 tw-text-dark-grey-300;
  }
}

.app-toggle-btn.p-button {
  .p-button-icon-right {
    @apply tw-text-dark-900 #{!important};
  }
}

.app-toggle-btn-sm {
  @apply app-toggle-btn app-btn-sm;
}

.app-toggle-btn-md {
  @apply app-toggle-btn app-btn-md;
}

.app-toggle-btn-lg {
  @apply app-toggle-btn app-btn-lg;
}

.app-toggle-btn-highlight {
  @apply tw-bg-transparent tw-border-dark-teal-600 tw-text-dark-900;
  @apply tw-text-dark-900 #{!important};

  .p-button-icon-right {
    @apply tw-text-dark-900 #{!important};
  }

  &:hover {
    @apply tw-bg-dark-100 tw-border-dark-300 tw-text-dark-900;
    @apply tw-text-dark-900 #{!important};
  }
}

.app-toggle-btn-sm.p-button.p-highlight {
  @apply app-toggle-btn-highlight
}

.app-toggle-btn-md.p-button.p-highlight {
  @apply app-toggle-btn-highlight
}

.app-toggle-btn-lg.p-button.p-highlight {
  @apply app-toggle-btn-highlight
}
