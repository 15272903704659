// *** Colors set up *************************************************

$primary: #4D777C;
$secondary: #a0a0a0;
$success: #94d661;
$info: #506486;
$warning: #ffa24f;
$danger: #ff6f5e;
$dark: #1b2735;
$light: #f5f5f5;
$white: #ffffff;
$gray-400: #506486;
$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"danger": $danger,
	"warning": $warning,
	"info": $info,
	"light": $light,
	"dark": $dark,
	"white": $white,
);

// *** General settings *************************************************

// *** Font Styles ***
$font-family-base: "Poppins", "Helvetica", sans-serif;
$font-family-sans-serif: "Poppins", "Helvetica", sans-serif;
$font-family-monospace: "Roboto", sans-serif;
$input-btn-font-family: $font-family-sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
.font-weight-semibold {
	font-weight: 500 !important;
}
$font-weight-bold: 600;
$headings-font-weight: 800;

.combobox-arrow {
	background-image: url("assets/icons/down-arrow.svg") !important;
}
// *** Non-Bootstrap Styles ***

$modal-xl: calc(100% - 48px);
$modal-lg: 80%;
$modal-md: 70%;

$box-shadow: 0 calc(2 / 14 * 1rem) calc(6 / 14 * 1rem) rgba(0, 0, 0, 0.16);

$border-radius: calc(8 / 14 * 1rem);
$border-radius-lg: calc(8 / 14 * 3 / 2.5 * 1rem);
$border-radius-sm: calc(8 / 14 * 2 / 2.5 * 1rem);
$btn-border-radius: calc(5 / 14 * 1rem);
