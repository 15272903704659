.app-checkbox {
  .p-checkbox-box {
    @apply tw-rounded tw-border-2 tw-border-dark-grey-500;
  }

  .p-checkbox-box.p-highlight {
    @apply tw-rounded tw-border-2 tw-border-dark-teal-600;
  }

  .p-checkbox-box .p-checkbox-icon {
    @apply tw-text-body4 tw-font-extrabold;
  }
}
