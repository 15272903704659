.app-calendar {
  .p-datepicker {
    @apply tw-border-none #{!important};
    // @apply tw-w-full

    .p-datepicker-header {
      @apply tw-border-none;

      & .p-datepicker-title {
        @apply tw-text-dark-900 tw-text-body2 #{!important};
      }

      & .p-datepicker-next, .p-datepicker-prev {
        @apply tw-bg-transparent tw-text-dark-teal-600 tw-rounded-full hover:tw-bg-deep-green-50 tw-p-2;
      }
    }

    .p-datepicker-group-container {
      @apply tw-gap-10;
    }

    // hide days labels
    table > thead > tr {
      @apply tw-hidden;
    }

    table tr td:first-child span {
      &.p-highlight {
        @apply tw-rounded-l-lg;
      }
    }

    table tr td:last-child span {
      &.p-highlight {
        @apply tw-rounded-r-lg;
      }
    }

    table td {
      @apply tw-p-0;
    }

    table td > span {
      @apply tw-h-10 tw-w-10 tw-rounded-none tw-border-none hover:tw-bg-dark-teal-100;

      &.p-highlight {
        @apply tw-bg-dark-teal-100;
      }
    }
  }
}
